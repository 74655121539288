import {SessionService} from './services/session.service';
import {LoginStateService} from './services/login-state.service';
import {
  AuthToken,
  LoginResponse,
  SingleSignOnService,
  TargetService,
  UserLibAccessTokenProviderService
} from '@kpro-software/users-lib';
import {EMPTY, of, switchMap} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ConsentService} from './services/consent.service';

export function initLoginState(loginStateService: LoginStateService,
                               cookieService: SessionService,
                               singleSignOnService: SingleSignOnService,
                               consentService: ConsentService,
                               userLibAccessTokenProviderService: UserLibAccessTokenProviderService) {
  return () => {
    // Methode zum Laden des Access-Tokens für die User-Bibliothek bereitstellen
    userLibAccessTokenProviderService.registerCallback(() => {
      return {accessToken: cookieService.accessToken, tokenType: cookieService.tokenType} as AuthToken;
    });

    const promises = [];

    // Den Single-Sign-On-Parameter in der URL überwachen und einloggen, bei Verfügbarkeit.
    promises.push(
      singleSignOnService.processSingleSignOn(TargetService.RESULTS)
        .pipe(
          catchError(() => of(null)),
          // @ts-ignore
          switchMap((login: LoginResponse | null) => {
            if (login !== null) {
              cookieService.removeCookies();
              cookieService.saveAll(login);
            }

            // LoginState laden
            if (cookieService.accessToken) {
              return loginStateService.initialize();

            } else {
              loginStateService.pushLoginState(null);
              return EMPTY;
            }
          })
        )
        // @ts-ignore
        .pipe(catchError(() => {
          cookieService.removeCookies();
          loginStateService.pushLoginState(null);
          return EMPTY;
        }))
        .toPromise()
    );

    // Cookie-Consent-Tool starten
    consentService.initialize();

    // Requests als promise zurückgeben
    return Promise.all(promises);
  };
}
