import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, skip, throwError} from 'rxjs';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {SessionService} from './services/session.service';
import {LoginStateService} from './services/login-state.service';
import {LoginService, RefreshTokenResponse} from '@kpro-software/users-lib';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshTokenRefreshInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(private readonly loginService: LoginService,
              private readonly cookie: SessionService,
              private readonly loginStateService: LoginStateService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      // @ts-ignore
      catchError((err) => {
        // Fehler die beim Login oder beim Aktualisieren des Refesh-Tokens entstehen müssen
        // gesondert behandelt werden
        if (req.url.includes('-authentication-token')) {
          // Logout
          this.loginStateService.logout();
          return throwError(err);
        }

        // Wenn es um einen anderen Status als 401 geht
        // rethrowen, da nur 401er interessant sind und mit dem Token Expire zusammenhängen können
        if (err.status !== 401) {
          return throwError(err);
        }

        if (this.refreshTokenRefreshInProgress) {
          return this.refreshTokenSubject.pipe(
            skip(1),
            filter(result => result !== null),
            take(1),
            switchMap(() => {
              return next.handle(this.addAuthToken(req));
            })
          );
        } else {
          this.refreshTokenRefreshInProgress = true;

          return this.loginService.refreshToken(this.cookie.accessToken ?? '', this.cookie.refreshToken ?? '')
            .pipe(
              // @ts-ignore
              switchMap((res: RefreshTokenResponse) => {
                this.refreshTokenRefreshInProgress = false;

                this.cookie.setRefreshToken(res.refreshToken);
                this.cookie.setAccessToken(res.accessToken);

                // Access-Token via Subject broadcasten
                this.refreshTokenSubject.next(res.accessToken);

                // Request authentifiziert replayen
                return next.handle(this.addAuthToken(req));
              }),
              catchError(() => {
                this.refreshTokenRefreshInProgress = false;

                // Logout, wenn es beim Token-Refresh zum Fehler kommt
                this.loginStateService.logout();

                return throwError(err);
              })
            );
        }
      })
    );
  }

  private addAuthToken(req: HttpRequest<any>): HttpRequest<any> {
    const accessToken = this.cookie.accessToken;

    if (!accessToken) {
      // Wenn kein Token da ist, Request nicht authentifizieren
      return req;
    }

    return req.clone({
      setHeaders: {
        Authorization: this.cookie.tokenType + ' ' + accessToken
      }
    });
  }
}
